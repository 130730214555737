/* eslint-disable no-unused-expressions */
import { isMobileOnly, isTablet, isMobile } from "react-device-detect"

const BTAG = "btag"
const BID = "bid"
const PID = "pid"
const LANG_IT = "it"
const LANG_ES = "es"
const LANG_DE = "de"
const LANG_NL = "nl"
const ONTARIO = "ca-on"
const UK = "gb"
const DOT_IT = ".it"
const DOT_ES = ".es"
const DOT_NL = ".nl"
const DOT_DE = ".de"
const DOT_COM = ".com"
const DOT_CO_UK = ".co.uk"
const DOT_ONT = ".com/en-ca/on"

const APPSFLYER_LINK = "https://app.appsflyer.com/"
const ITUNES_LINK = "https://itunes.apple.com/GB/app/"
const ADS_LINK = "http://ads.leovegas.com/redirect.aspx?redirectUrl=https%3A%2F%2Fwww.leovegas"
const ADS_HELP_LINK = "http://ads.leovegas.com/redirect.aspx?redirectUrl=https%3A%2F%2Fhelp.leovegas.com"

const CTA_ADS_LINK = "http://ads.leovegas.com/redirect.aspx?redirectUrl="

const GOOGLE_PLAY_STORE = "http://app.appsflyer.com/com.leovegas.leocasino/?"

const APK_LINK = "https://app.appsflyer.com/com.leovegas.leocasino-Standalone/?af_r=http://android.leovegas.com&APP_ID=com.leovegas.leocasino"

const ITALY_APK_LINK = "https://android-app-landing-page.leo-prod-common.lvg-tech.net/"

const parseLink = value => {
  if (!value) {
    return null
  }

  const title = value.match(/\[(.*?)\]/)?.[1]
  const url = value.match(/\((.*?)\)/)?.[1]

  return {
    title,
    url
  }
}

const CtaLinks = (
  bonusProgramId,
  defaultMarketingSourceId,
  defaultMediaId,
  iosAppId,
  downloadAppAndroidLinkType,
  pageType,
  signupUrl,
  loginUrl,
  urlLanguage,
  contentLanguage,
  verticals,
  gameRules
) => {
  const bpid = bonusProgramId ? `&bpid=${bonusProgramId}` : ""

  const getDomainZone = lang => {
    switch (lang) {
      case LANG_IT:
        return DOT_IT
      case LANG_ES:
        return DOT_ES
      case LANG_NL:
        return DOT_NL
      case LANG_DE:
        return DOT_DE
      case ONTARIO:
        return DOT_ONT
      case UK: {
        let { hostname } = window.location
        hostname = hostname.replace("promo.", "")

        if (hostname === "localhost") {
          return DOT_COM
        }

        const tld = hostname.substring(hostname.indexOf("."))

        if (tld === ".co.uk") {
          return DOT_CO_UK
        }

        return DOT_COM
      }
      default:
        return DOT_COM
    }
  }

  const checkParams = () => {
    if (typeof window === "undefined") return null

    const urlParams = new URLSearchParams(window.location.search)
    const { length } = Array.from(urlParams)
    return length > 0
  }

  const hasQueryString = checkParams()

  const getUrlParams = () => {
    if (typeof window === "undefined") return null

    const urlParams = new URLSearchParams(window.location.search)

    let params = ""

    if (hasQueryString) {
      params = urlParams.toString()
    } else {
      // Otherwise, we create new parameters for the default marketing source id and default media id.
      params = `pid=${defaultMarketingSourceId}&bid=${defaultMediaId}&${bpid}`
    }

    return params
  }

  const domainZone = getDomainZone(urlLanguage)

  const LEOVEGAS_LINK = `https://www.leovegas${domainZone}/`
  const LEOVEGAS_HELP_LINK = "https://help.leovegas.com/"

  const signup = signupUrl || `https://www.leovegas${domainZone}/signup/`

  const login = loginUrl || `https://www.leovegas${domainZone}/login/`

  const urlParams = getUrlParams()

  const getDownloadAppQS = lang => {
    if (typeof window === "undefined") return null
    const qs = new URLSearchParams(window.location.search)
    if (hasQueryString) {
      let downloadAppQS = ""
      qs.has(BTAG) && (downloadAppQS += `&af_sub1=${qs.get(BTAG)}`)
      qs.has("af_prt") && (downloadAppQS += `&af_prt=${qs.get("af_prt")}`)

      isMobileOnly && (downloadAppQS += `&pid=mobile`)
      isTablet && (downloadAppQS += `&pid=tablet`)
      !isMobile && (downloadAppQS += `&pid=desktop`)

      downloadAppQS += `&c=${lang}_LP`
      qs.has(PID) && (downloadAppQS += `&af_sub2=${qs.get(PID)}`)
      qs.has(BID) && (downloadAppQS += `&af_sub3=${qs.get(BID)}`)

      return downloadAppQS
    }
    return `&pid=landingPage`
  }

  const createUrl = (_verticals, str, _pageType) => {
    if (_verticals) {
      const url = {}

      _verticals.forEach(vertical => {
        url[vertical] = str(vertical).toLowerCase()
      })

      return url
    }

    const lobby = _pageType

    return str(lobby)
  }

  const getVerticalSort = vertical => {
    const _vertical = vertical?.replace(/\s/g, "")
    return _vertical === "sportsbook" ? "sports" : _vertical
  }

  const getRedirectUrl = () => {
    const queryStrings = `&pid=${defaultMarketingSourceId}&bid=${defaultMediaId}${bpid}`

    const adsLink = signupUrl ? `http://ads.leovegas.com/redirect.aspx?redirectUrl=${signupUrl}` : `${ADS_LINK}${domainZone}%2Fsignup`

    const str = vertical => {
      const verticalSort = getVerticalSort(vertical?.replace(/\s/g, ""))

      if (urlLanguage === "gb") {
        return hasQueryString
          ? `https://www.leovegas.co.uk/auth?intent=signup&${urlParams}${bpid}&wo=${verticalSort}`
          : `http://ads.leovegas.com/redirect.aspx?redirectUrl=https%3A%2F%2Fwww.leovegas.co.uk%2Fauth?intent=signup&${queryStrings}&wo=${verticalSort}`
      }

      return hasQueryString
        ? `${signup}?${urlParams}&lobby=${vertical?.replace(/\s/g, "")}${bpid}&verticalSort=${verticalSort}`
        : `${adsLink}%3Flobby%3D${vertical?.replace(/\s/g, "")}${queryStrings}&verticalSort=${verticalSort}`
    }

    return createUrl(verticals, str, pageType)
  }

  const getLoginUrl = () => {
    const queryStrings = `&pid=${defaultMarketingSourceId}&bid=${defaultMediaId}${bpid}`

    const str = vertical => {
      const verticalSort = getVerticalSort(vertical?.replace(/\s/g, ""))
      return hasQueryString
        ? `${login}?${urlParams}&lobby=${vertical?.replace(/\s/g, "")}${bpid}&verticalSort=${verticalSort}`
        : `${ADS_LINK}${domainZone}%2Flogin%3Flobby%3D${vertical?.replace(/\s/g, "")}${queryStrings}&verticalSort=${verticalSort}`
    }

    return createUrl(verticals, str, pageType)
  }

  const getAndroidLink = type => {
    if (!type) return false
    const isAPK = type?.toLowerCase() === "apk file"
    const apkUrl = contentLanguage === "it" ? ITALY_APK_LINK : APK_LINK
    return isAPK ? apkUrl : GOOGLE_PLAY_STORE
  }

  const getDownloadAppLinks = () => {
    const downloadAppQS = getDownloadAppQS(contentLanguage)

    const AndroidLink = getAndroidLink(downloadAppAndroidLinkType)

    const iStoreLink = iosAppId && `${APPSFLYER_LINK}${iosAppId}/?af_r=${ITUNES_LINK}${iosAppId}&APP_ID=${iosAppId}${downloadAppQS}`

    const googlePlayLink = AndroidLink && `${AndroidLink}${downloadAppQS}`

    return {
      iStoreLink,
      googlePlayLink
    }
  }

  // build footer links
  const getMiscLinks = () => {
    const link = hasQueryString ? LEOVEGAS_LINK : `${ADS_LINK}${domainZone}%2F`
    const helpLink = hasQueryString ? LEOVEGAS_HELP_LINK : `${ADS_HELP_LINK}%2F`

    const gameRulesData = parseLink(gameRules)

    const isUK = contentLanguage === "gb" // temporary condition as LV UK migrated to Restone

    return {
      aboutLeoVegas: `${link}support/about/?${urlParams}`,
      responsibleGaming: isUK ? `${link}safer-gambling/?${urlParams}` : `${link}support/responsible-gaming/?${urlParams}`,
      gameRules: {
        title: gameRulesData?.title,
        url: `${helpLink}${gameRulesData?.url}/?${urlParams}`
      },
      terms: `${link}terms/?${urlParams}`,
      cookiePolicy: `${link}cookie-policy/?${urlParams}`,
      privacyPolicy: `${link}privacy-policy/?${urlParams}`,
      gamesSection: `${link}?${urlParams}`
    }
  }

  const getCtaLink = url => {
    const queryStrings = `pid=${defaultMarketingSourceId}&bid=${defaultMediaId}${bpid}`

    const s = url?.indexOf("?") > 0 ? "&" : "?"

    const str = vertical => {
      const verticalSort = getVerticalSort(vertical?.replace(/\s/g, ""))
      return hasQueryString
        ? `${url}${s}${urlParams}&lobby=${vertical?.replace(/\s/g, "")}${bpid}&verticalSort=${verticalSort}`
        : `${CTA_ADS_LINK}${url}${s}${queryStrings}&verticalSort=${verticalSort}`
    }

    return createUrl(verticals, str, pageType)
  }

  return {
    redirectUrl: getRedirectUrl(),
    loginUrl: getLoginUrl(),
    downLoadLinks: getDownloadAppLinks(),
    miscLinks: getMiscLinks(),
    urlParams,
    getCtaLink
  }
}

export default CtaLinks
